import "./navbar.css"
import logo from "./logo.jpeg"
import SideBar from "../sidebar/sidebar"

function Navbar() {
  return (
    <div id="navbar">
      <span id="menuButton">
        <a id="menuButtonAnchor" href="">
          <img id="logo" src={logo} alt="" />
          <p className="navbarText">Geyik App</p>
        </a>
      </span>
      <span id="menuItems">
        <a className="menuItem" href="#geyik">
          Geyik
        </a>
        <a className="menuItem" href="#yemekhane">
          Yemekhane
        </a>
        <a className="menuItem" href="#ring">
          Ring
        </a>
        <a className="menuItem" href="#topluluklar">
          Topluluklar
        </a>
      </span>
      <span id="download">
        <a href="#indir" id="downloadAnchor">
          <p className="navbarText" id="downloadText">
            ↓ İndir
          </p>
        </a>
      </span>
      <SideBar id="sidebar" />
    </div>
  )
}
export default Navbar
