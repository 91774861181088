import "./sidebar.css"
import { useState } from "react"
import logo from "./logo.jpeg"
import DownloadButton from "../../components/shared/DownloadButton.js"
function SideBar() {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen)
  }

  return (
    <>
      <button id="sidebarButton" onClick={toggleSidebar}>
        <span class="line"></span>
        <span class="line"></span>
        <span class="line"></span>
      </button>
      <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
        <div id="sidebarContent">
          <div id="sidebarTop">
            <a href="" id="sidebarLogo">
              <img
                className="navbarImage"
                id="sidebarLogoImage"
                src={logo}
                alt=""
              />
              <p>Geyik App</p>
            </a>
            <button id="exitButton" onClick={toggleSidebar}>
              <p id="exitButtonText">✖</p>
            </button>
          </div>
          <div id="sidebarTopBorder"></div>
          <div id="sidebarMenuItems">
            <a className="sidebarMenuItem" href="#geyik" onClick={toggleSidebar}>
              Geyik
            </a>
            <a className="sidebarMenuItem" href="#yemekhane" onClick={toggleSidebar}>
              Yemekhane
            </a>
            <a className="sidebarMenuItem" href="#ring" onClick={toggleSidebar}>
              Ring
            </a>
            <a className="sidebarMenuItem" href="#topluluklar" onClick={toggleSidebar}>
              Topluluklar
            </a>
          </div>
          <div id="sidebarBottom">
            <DownloadButton platform="android" />
            <DownloadButton platform="ios" />
          </div>
        </div>
      </div>
    </>
  )
}
export default SideBar
