import React from "react";
import TextBox from "../../components/section/TextBox";

const FeatureDisplay = ({ 
    id,
    title, 
    text, 
    image,
    swapImageAndText=false,
    grayBackground=false,
    headerAlignRight=false,
    headerAlignLeft=false,
    imageClassName="",
    textDivClassName="",
}) => {
    return (
        <div id={id} className={`flex justify-center ${grayBackground ? "bg-[#f6f6f6]" : "bg-white"}`}>
            <div className={`min-h-[52rem] max-w-[90rem] flex-col lg:gap-[6.75%] gap-24 md:px-20 px-4 flex ${swapImageAndText ? "lg:flex-row-reverse" : "lg:flex-row"}`}>
                <img 
                    className={`relative sm:px-0 px-6 object-scale-down h-[55rem] ${imageClassName}`}
                    src={image} 
                    alt="feature preview" 
                />
                <div className={`my-auto lg:pt-20 sm:pt-16 pt-11 mx-6 ${textDivClassName}`}>
                    <TextBox title={title} headerAlignRight={headerAlignRight} headerAlignLeft={headerAlignLeft}>{text}</TextBox>
                </div>
            </div>
        </div>
    );
}

export default FeatureDisplay;