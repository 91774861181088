import MidsectionsCombined from "./sections/midsectionsCombined"

import Navbar from "./sections/navbar/navbar"

export default function App() {
  return (
    <>
      <div id="navbarContainer">
        <Navbar />
      </div>
      <MidsectionsCombined />
    </>
  )
}
