import React from "react";
import TextBox from "../../components/section/TextBox";
import DownloadButton from "../../components/shared/DownloadButton";

const DownloadPanel = ({ 
    id,
    title, 
    text, 
    image,
    smallImage,
}) => {
    return (
        <div id={id} className="flex justify-center">
            <div className="min-h-[52rem] max-w-[90rem] flex-col md:px-20 px-4 flex items-center">
                <div className="mx-6 pt-16 pb-10 lg:py-28 py md:pt-28`">
                    <TextBox title={title}>{text}</TextBox>
                </div>
                <img 
                    className="lg:px-0 px-6 w-full"
                    src={image}
                    srcSet={`${smallImage} 800w, ${image} 2024w`}
                    alt="website and app preview" 
                />
                <p className="text-center py-5 md:text-4xl md:font-semibold sm:text-3xl text-2xl font-SF_Compact">Keşfe çıkmaya ne dersin?</p>
                <div id="indir" className="flex justify-center lg:flex-row flex-col gap-1 pb-20">
                    <DownloadButton platform="android" />
                    <DownloadButton platform="ios" />
                </div>
            </div>
        </div>
    );
}

export default DownloadPanel;