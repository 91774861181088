import React from "react"

const TextBox = ({ 
    title, 
    children,
    headerAlignRight=false,
    headerAlignLeft=false,
    topLevelHeading=false,
    whiteText=false,
}) => {
    return (
        <div className={`flex flex-col gap-11 ${whiteText && "text-white font-light"}`}>
            {topLevelHeading ?
                <h1 className={`${headerAlignLeft ? "lg:text-left" : (headerAlignRight && "lg:text-right")} text-center md:text-[5.2rem] md:leading-[6rem] leading-[2.25rem] text-[2rem] font-KG_Blank_Space_Solid font-normal`}>{title}</h1>
                :
                <h2 className={`${headerAlignLeft ? "lg:text-left" : (headerAlignRight ? "lg:text-right" : "lg:leading-[5rem]")} text-center md:leading-[4rem] md:text-[3.4rem] text-3xl font-KG_Blank_Space_Solid font-normal`}>{title}</h2>
            }
            <p className="text-justify mx-auto md:text-[1.4rem] text-lg font-SF_Compact md:leading-[1.4rem] leading-tight">{children}</p>
        </div>
    )
}

export default TextBox