import React from "react"
import googlePlayBadge from "../../assets/google-play-badge.png"
import appStoreBadge from "../../assets/app-store-badge.svg"

/**
 * @param {string} platform - The platform to display the download button for. android or ios.
 */
const DownloadButton = ({ platform }) => {
  return (
    <a
      href={
        platform === "android"
          ? "https://play.google.com/store/apps/details?id=com.acm.geyik_app"
          : "https://apps.apple.com/tr/app/geyik-for-students/id6444013289"
      }
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        style={{
          width: "auto",
          height: "55px",
          borderRadius: "0px",
          margin: "0px",
        }}
        alt={
          platform === "android"
            ? "Get it on Google Play"
            : "Download on the App Store"
        }
        src={platform === "android" ? googlePlayBadge : appStoreBadge}
      />
    </a>
  )
}

export default DownloadButton
