import React from "react";
import TextBox from "../../components/section/TextBox";

const Header = ({ 
    title, 
    text, 
    image,
    headerAlignRight=false,
    headerAlignLeft=false,
}) => {
    return (
        <div className="flex justify-center [background:linear-gradient(180deg,rgb(73,36,51.54)_0%,rgb(141,44,70)_100%)]">
            <div className="flex lg:flex-row flex-col sm:min-h-[52rem] min-h-[40rem] max-w-[90rem] lg:gap-8 md:px-20 px-9 overflow-hidden">
                <div className={`my-auto lg:pb-12 pb-4 lg:pt-24 pt-28 lg:ml-6`}>
                    <TextBox 
                        topLevelHeading 
                        whiteText
                        title={title} 
                        headerAlignRight={headerAlignRight} 
                        headerAlignLeft={headerAlignLeft}
                    >
                        {text}
                    </TextBox>
                </div>
                <img 
                    className={`lg:h-[53rem] relative lg:mr-6 lg:ml-0 ml-5 lg:mb-0 md:mb-[-21rem] sm:mb-[-10rem] mb-[-4rem] lg:top-[9rem]`}
                    src={image} 
                    alt="header art" 
                />
            </div>
        </div>
    );
}

export default Header;