import React from "react"
import FeatureDisplay from "./featureDisplay/FeatureDisplay"
import Header from "./header/Header"
import geyik from "../assets/Geyik.svg"
import geyikMobile1 from "../assets/Geyik Mobile 1.svg"
import geyikMobile2 from "../assets/Geyik Mobile 2.svg"
import geyikMobile3 from "../assets/Geyik Mobile 3.svg"
import yemekListesi from "../assets/image 1.svg"
import geyikMobile4 from "../assets/Geyik-Mobile-4.svg"
import geyikMobile4small from "../assets/Geyik-Mobile-4-small.svg"
import DownloadPanel from "./downloadPanel/DownloadPanel"

const MidsectionsCombined = () => {
  const title = "Lorem ipsum dolor sit amet, elit consectetur adipiscing elit"
  const text =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus."

  return (
    <>
      <Header
        title="Hacettepe’yi Keşfet"
        text="Geyik ile Hacettepe’de olan bitenleri, kaçırma! Günlük yemekhane menüsü, ring saati ve daha fazlası..."
        image={geyik}
        headerAlignLeft
      />
      <FeatureDisplay
        id="geyik"
        title="Üniversite Hayatını Kolaylaştır!"
        text="Geyik, Hacettepe öğrencilerinin kampüs ve akademik hayatlarını koylaştırmak amacıyla için geliştirilmiş bir sürü işlevi olan bir kampüs uygulamasıdır. Etkinlikler, yemekhane menüsü, ring saatleri, GPA hesaplama, akademik takvim ve duyurular gibi bir çok özelliği ile ön plana çıkmaktadır."
        image={geyikMobile1}
        imageClassName="top-[-7rem] mb-[-14rem] xl:h-[56.4rem] sm:h-[54rem] h-auto"
        textDivClassName="lg:pb-52 pb-20"
      />
      <FeatureDisplay
        id="yemekhane"
        title="Günün Yemeğini Kaçırma"
        text="Geyik ile kampüs yemekhanesinde çıkan yemekleri kalori ve alerjenleri ile beraber takip edebilir, Tek tıkla günün yemeğini hızlı bir şekilde öğrenebilirsin!  Sadece günün değil ayrıca yarının ve açıklanmış olan bütün yemek listelerini görebilirsin."
        image={yemekListesi}
        imageClassName="xl:mr-16 mx-auto mb-[-44rem] top-[-17rem] lg:top-[-7.5rem] xl:w-[33rem] w-[27rem]"
        textDivClassName="lg:pb-48 pb-60"
        swapImageAndText
        headerAlignLeft
        grayBackground
      />
      <FeatureDisplay
        id="ring"
        title="Ringi Kaçırma!"
        text="Boşuna ringi erkenden bekleme. Geyik ile önümüzdeki ring kalış saatini görebilir ve bütün ring saatlerine bakabilirsin. Böylelikle planlayarak ringi kaçırmayı veya fazla beklemeyi önleyebilirsin."
        image={geyikMobile2}
        imageClassName="top-[-13.5rem] mb-[-20rem] xl:h-[60rem] sm:h-[51rem] h-auto"
        textDivClassName="lg:pb-64 pb-60"
        swapImageAndText
        headerAlignLeft
      />
      <FeatureDisplay
        id="topluluklar"
        title="Hacettepe’de bu hafta neler var?"
        text="Topluluklar tarafından düzenlenen etkinliklerden haberdar olabilir, etkinlik detaylarını Geyik üzerinden takip edebilirsin. Topluluk etkinliklerini kaçırma!"
        image={geyikMobile3}
        imageClassName="top-[-11rem] mb-[-18rem] xl:h-[60rem] sm:h-[51rem] h-auto"
        textDivClassName="lg:pb-52 pb-20"
        headerAlignRight
        grayBackground
      />
      <DownloadPanel
        title="Üniversite Hayatını Kolaylaştır!"
        text="Geyiği indirerek üniversitedeki olan bitenleri kaçırma!"
        image={geyikMobile4}
        smallImage={geyikMobile4small}
      />
    </>
  )
}

export default MidsectionsCombined
